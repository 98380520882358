import { useEffect, useState } from 'react';

export default function OccurrenceStatusFilter({ statuses, onFilterChange = () => { } }) {
    const [selectedValue, setSelectedValue] = useState("-1"); // Default to All

    useEffect(() => {
        if (selectedValue === "-1") {
            // When "All" is selected, include all options
            onFilterChange([]);
        } else {
            onFilterChange([selectedValue]);
        }
    }, [selectedValue, onFilterChange]);

    const handleChange = (e) => {
        setSelectedValue(e.target.value);
    };

    if (!statuses) {
        return null;
    }

    const sortedStatuses = [...statuses].sort((a, b) => {
        const extractParts = (str) => {
            const match = str.match(/(\d+)([a-z]*)/i);
            return [parseInt(match[1]), match[2] || ""];
        };
        const [numA, alphaA] = extractParts(a.name);
        const [numB, alphaB] = extractParts(b.name);

        if (numA === numB) {
            return alphaA.localeCompare(alphaB);
        } else {
            return numA - numB;
        }
    })

    return (
        <label className="filter-label">Occurrence status
            <select value={selectedValue} onChange={handleChange} className="text-input">
                <option value="-1">All</option>
                {sortedStatuses.map(status => (
                    <option value={status.name} key={status.id}>
                        {`${status.name} - ${status.description}`}
                    </option>
                ))}
            </select>
        </label>
    );
}
