import { useState } from 'react';

export default function WantedUnwantedFilter({ onFilterChange }) {
    const [selectedValue, setSelectedValue] = useState('all');

    const handleChange = (e) => {
        setSelectedValue(e.target.value);
        onFilterChange?.(e.target.value);
    };

    return (
        <label className="filter-label">
            Status
            <select value={selectedValue} onChange={handleChange} className="text-input">
                <option value="all">All</option>
                <option value="wanted">Wanted</option>
                <option value="unwanted">Unwanted</option>
            </select>
        </label>
    );
}
