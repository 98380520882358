import { useContext, useState, useCallback } from 'react';
import { TableStateContext } from '../../model/table_state_context';
import { specificityList } from '../../model/specificity';
import { DebounceInput } from 'react-debounce-input';
import WantedUnwantedFilter from './wanted_unwanted_filter';
import OccurrenceStatusFilter from './occurrence_status_filter';
import LocalityFilter from './locality_filter';
import ExportCsv from './export_csv';


export default function TargetlistGridHeader({ toggleFilters, hideTable, onGroupByChange }) {
    const { numberOfItems, tableSearchForString, setTableSearchForString, setOccurrenceStatusFilter, setLocalityFilter, setWantedFilter, countries, occStatus, setGroupBy, table } = useContext(TableStateContext);

    const searchPlaceholder = `Search ${numberOfItems} results`;
    const [showWantedUnwantedFilter, setshowWantedUnwantedFilter] = useState(true);

    const handleGroupByChange = (event) => {
        const groupByValue = event.target.value;
        setGroupBy(groupByValue);
        onGroupByChange(groupByValue);
        if (groupByValue === "species") {
            setshowWantedUnwantedFilter(true);
        } else {
            setshowWantedUnwantedFilter(false);
            setWantedFilter('all');
        }
    };

    const addOccurrenceStatusFilter = useCallback((occurrenceStatus) => {
        setOccurrenceStatusFilter(occurrenceStatus);
    }, [setOccurrenceStatusFilter]);

    const changeValue = useCallback((event) => {
        setTableSearchForString(event.target.value);
    }, [setTableSearchForString])

    const addWantedUnwantedFilter = useCallback((value) => {
        setWantedFilter(value);
    }, [setWantedFilter]);

    return (
        <div className="d-flex flex-row justify-content-between position-relative">
            <div className="d-flex flex-row align-items-end gap-2">
                <label className="filter-label">
                    Filter by
                    <select defaultValue='species' onChange={handleGroupByChange} className="filter-dropdown">
                        {specificityList.map((item) => <option key={item} value={item}>{item}</option>)}
                    </select>
                </label>
                <label className="filter-label">
                    Search
                    <DebounceInput
                        type="search"
                        placeholder={searchPlaceholder}
                        value={tableSearchForString}
                        debounceTimeout={400}
                        onChange={changeValue}
                        className="text-input"
                        minLength={2}
                    />
                </label>
                {showWantedUnwantedFilter ?
                    <WantedUnwantedFilter onFilterChange={addWantedUnwantedFilter} />
                    : null}
                <OccurrenceStatusFilter statuses={occStatus} onFilterChange={addOccurrenceStatusFilter} />
                <LocalityFilter countries={countries} onChange={(countryList) => setLocalityFilter(countryList)} />
                <small className="mb-2 ms-2">
                    {numberOfItems > 1 ? `${numberOfItems} results found` : `${numberOfItems} result found`}
                </small>
                {/*<button className="btn-icon filter" onClick={toggleFilters}></button>*/}
            </div>
            <div className="tl-grid-header-right">
                <button className="btn-main primary chevron-up" onClick={hideTable}>charts</button>
                <ExportCsv table={table} />
            </div>
        </div>
    )
}