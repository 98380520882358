import { useState, useEffect } from 'react';
import { DebounceInput } from 'react-debounce-input';

function PercentageInput({ value, setValue = () => { } }) {
    const [error, setError] = useState(null);

    const changeValue = (e) => {
        const numValue = Number(e.target.value);
        if (numValue < 0) {
            setError('value must be greater than 0');
        } else if (numValue > 100) {
            setError('value must be less than 100');
        } else {
            setError(null);
        }
        setValue(numValue);
    }

    return (
        <>
            <div className="d-flex flex-column">
                <DebounceInput className="text-input" type="number" value={value} onChange={changeValue} debounceTimeout={400} />
                {error ? (
                    <div className="form-text text-danger" data-cy="labelinput_error">
                        {error}
                    </div>
                ) : null}
            </div>
        </>
    )
}


function PercentageRange({ min, max, setMin = () => { }, setMax = () => { } }) {
    return (
        <>
            <div className="mb-3">
                <div className="d-flex flex-row">
                    <PercentageInput value={min} setValue={setMin} /><span className="mr-2 ml-2 mt-1">and</span> <PercentageInput value={max} setValue={setMax} />
                </div>
                {max < min ? <div className="form-text text-danger" data-cy="labelinput_error">
                    Max must be greater than min
                </div> : null}
            </div>
        </>
    )
}

// shows a percentage range to filter on
// (like locality we 'manually' filter this otherwise it was way too slow)
export default function PercentageFilter({ title, onChange = () => { } }) {
    const [minCoverage, setMinCoverage] = useState(0);
    const [maxCoverage, setMaxCoverage] = useState(100);

    useEffect(() => {
        if (minCoverage >= 0 && maxCoverage <= 100 && maxCoverage >= minCoverage) {
            onChange({ min: minCoverage / 100, max: maxCoverage / 100 });
        }
    }, [onChange, minCoverage, maxCoverage])

    return (
        <>
            <div>
                <p className="mb-1">{title}</p>
                <PercentageRange min={minCoverage} max={maxCoverage} setMin={setMinCoverage} setMax={setMaxCoverage} />
            </div>
        </>
    );
}	