import { useEffect, useRef, useContext } from 'react';
import { GoogleChartsContext } from '../../useGoogleCharts';

export function PieChart({ percentage, colors, title, id, cssClass }) {
	const chartRef = useRef(null);
	const { google } = useContext(GoogleChartsContext);

	useEffect(() => {
		if (google && percentage !== undefined && chartRef.current) {
			// Create the data table.
			const data = new google.visualization.DataTable();
			data.addColumn('string', 'text');
			data.addColumn('number', 'number');
			data.addRows(2);
			data.setValue(0, 0, '');
			data.setValue(0, 1, 0.0);
			data.setValue(1, 0, '');
			data.setValue(1, 1, 100.0);

			// Set chart options
			const options = {
				'pieHole': 0.6,
				'pieSliceBorderColor': "none",
				'colors': colors,
				"backgroundColor": "transparent",
				'legend': {
					'position': "none"
				},
				'pieSliceText': "none",
				'tooltip': {
					'trigger': "none"
				}
			};


			// Instantiate and draw our chart, passing in some options.
			const newChart = new google.visualization.PieChart(document.getElementById(id)); //document.getElementById(id));

			data.setValue(0, 1, percentage);
			data.setValue(1, 1, 100-percentage);
			newChart.draw(data, options);

			// initial value
			var animationPercentage = 0;
			// start the animation loop

			//var handler = setInterval(function () {
			//	// values increment
			//	animationPercentage += 1;
			//	// apply new values
			//	data.setValue(0, 1, animationPercentage);
			//	data.setValue(1, 1, 100 - animationPercentage);

			//	// update the pie
			//	newChart.draw(data, options);
			//	// check if we have reached the desired value
			//	if (animationPercentage === 100 || animationPercentage >= percentage)
			//		// stop the loop
			//		clearInterval(handler);
			//}, 10);


			// setChart(newChart);
		}

	}, [google, percentage, colors, chartRef])


	return (
		<div className={`donutchart-container ${cssClass}`}>
			{!google}
			<div className="donutchart" id={id} ref={chartRef} ></div>
			<div className="labelOverlay">
				<p className="used-size">{percentage}%</p>
			</div>
			<p className="donutchart-title">{title}</p>
		</div>

	);
}
