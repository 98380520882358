import { useState, useRef, useEffect } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';
import { flexRender } from '@tanstack/react-table';
import TableFilterInput from './table_filter_input';
import ReactDOM from 'react-dom';

export default function TableHeader({ header, table, sorting }) {
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    const containerRef = useRef(null);

    let sortingIcon = undefined;
    if (header.column.getCanSort()) {
        sortingIcon = <TiArrowUnsorted />;
        if (sorting.length) {
            const thisColumnSorted = sorting.find(s => s.id === header.id);
            if (thisColumnSorted) {
                if (thisColumnSorted.desc) {
                    sortingIcon = <TiArrowSortedDown />;
                } else if (thisColumnSorted.asc) {
                    sortingIcon = <TiArrowSortedUp />;
                } else {
                    sortingIcon = <TiArrowUnsorted />;
                }
            }
        }
    }

    const toggleTooltip = (event) => {
        const { top, left, width } = event.currentTarget.getBoundingClientRect();
        const tooltipWidth = tooltipRef.current ? tooltipRef.current.offsetWidth : 250;
        let tooltipTop = top + window.scrollY + 40;
        let tooltipLeft = left + window.scrollX + width + 10; // Default position right

        const viewportWidth = window.innerWidth;

        //tooltip positioning left if it doesn't fit on right
        if (left + width + tooltipWidth > viewportWidth) {
            tooltipLeft = left + window.scrollX - tooltipWidth - 10;
        }

        setTooltipPosition({ top: tooltipTop, left: tooltipLeft });
        setShowTooltip(!showTooltip);
    };

    const handleClickOutside = (event) => {
        if (tooltipRef.current && !tooltipRef.current.contains(event.target) && !containerRef.current.contains(event.target)) {
            setShowTooltip(false);
        }
    };

    useEffect(() => {
        if (showTooltip) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);


    const renderTooltipPortal = () => {
        if (showTooltip) {
            return ReactDOM.createPortal(
                <div
                    className="tooltip-box"
                    style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
                    ref={tooltipRef}
                    dangerouslySetInnerHTML={{ __html: `<strong>${header.column.columnDef.tooltipTitle}</strong><p>${header.column.columnDef.tooltipText}</p>` }}
                />,
                document.getElementById('table-tooltip-portal')
            );
        }
        return null;
    };

    return (
        <th key={header.id}>
            <div className="d-flex justify-content-between">
                {header.isPlaceholder
                    ? null
                    : <div className={header.column.getCanSort() ? 'cursor-pointer' : ''} onClick={(e) => { e.preventDefault(); header.column.toggleSorting(header.column.getNextSortingOrder(), header.column.getCanMultiSort()); }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {sortingIcon}
                    </div>}
                {(header.column.getCanFilter() && header.column.columnDef.showFilterInline) && (
                    <div>
                        <TableFilterInput column={header.column} table={table} />
                    </div>
                )}
                {header.column.columnDef.tooltipTitle && header.column.columnDef.tooltipText && (
                    <div className="tooltip-container" onClick={toggleTooltip} ref={containerRef}>
                        <div className="tooltip-icon">?</div>
                    </div>
                )}
            </div>
            {renderTooltipPortal()}
        </th>
    );
}
