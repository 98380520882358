import React, { Component } from 'react';
import {  Navbar, NavbarBrand, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-main" id="navbar-main">
                    <div className="navlink-container">
                        <NavbarBrand tag={Link} to="/"><img src="/images/logo-arise-white.svg" className="logo navbar-logo" id="navbar-logo" alt="arise logo" /></NavbarBrand>
                    </div>
                    <input type="checkbox" id="hamburger-input" />
                    <label htmlFor="hamburger-input" className="hamburger-label">
                        <div className="spinner diagonal part-1"></div>
                        <div className="spinner horizontal"></div>
                        <div className="spinner diagonal part-2"></div>
                    </label>

                    <div id="hamburger-menu" className="transparent">
                        <div className="flex-column">
                            <NavLink tag={Link} to="/targetlist">Home</NavLink>
                            <a className="paragraph light nav-link" href="https://www.arise-biodiversity.nl/" target="_blank">ARISE Biodiversity</a>
                        </div>
                        <a href="mailto:arise@naturalis.nl" target='_blank' className="paragraph light nav-link">Contact us</a>
                    </div>
                </Navbar>
            </header>
        );
    }
}
