import { useEffect, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createTargetlistItemFromJson, groupTargetlistItemsByKey } from '../model/target_list_columns';
import { specificityList, Specificity, isEqualOrMoreSpecific } from '../model/specificity';

// when developing, use a smaller set of data to test some groupBy stuff etc.
const DEVELOPMENT_LIMIT_TO = Number.MAX_SAFE_INTEGER;
const COUNTRY_KEY = 'speciesLocality';
const OCCURRENCE_STATUS_KEY = 'speciesOccurrenceStatus';

const filterByLocality = (items, countriesList) => {
    if (countriesList.length === 0) return items;
    return items.filter(item => item[COUNTRY_KEY].some(element => countriesList.includes(element)));
}

const filterByPercentage = (items, { percentageKey, min, max }) => {
    if (min <= 0 && max >= 100) return items;
    return items.filter(item => Number(item[percentageKey]) >= Number(min) && Number(item[percentageKey]) <= Number(max));
}

// occurrenceStatusList is e.g. [0, 1, 4]
// item.occurrenceStatus is something like '1a' of '1b, 0, 4a'
const filterByOccurrenceStatus = (items, occurrenceStatusList) => {
    if (occurrenceStatusList.length === 0) return items;
    return items.filter(item => {
        const statusList = item[OCCURRENCE_STATUS_KEY]?.split(',').map(status => status.trim()) || [];
        return statusList.some(status => occurrenceStatusList.includes(status));
    });
}

//wanted/unwanted status
const filterByStatus = (items, status) => {
    if (status === 'all') return items;
    return items.filter(item => {
        if (status === 'wanted') {
            return item.wanted;
        } else if (status === 'unwanted') {
            return !item.wanted;
        }
        return true;
    });
};


// if any word in 'a' starts with 'b' this will match
const stringCompareFn = (a, b) => {
    return a.toLowerCase().startsWith(b.toLowerCase()) || a.toLowerCase().includes(` ${b.toLowerCase()} `);
}

const filterByString = (items, stringFilter, filterColumns, isStrictSearch = false) => {
    if (!stringFilter || stringFilter.length === 0) return items;
    return items.filter(item => filterColumns.some(column =>
        isStrictSearch ? item[column] === stringFilter : stringCompareFn(item[column], stringFilter)));
    // return items.filter(item => filterColumns.some(column => {
    // 	const included = stringCompareFn(item[column], stringFilter);
    // 	if (included) console.log(`found ${stringFilter} in ${item[column]} while looking in column ${column}`);
    // 	return included;
    // }));
};

// the custom hook that will fetch the data from the api
// ONLY USE THIS HOOK IN THE TABLE CONTEXT
// (it will work everywhere, but the items will be refetched, so it's very wasteful)
export const useTargetlist = () => {
    const [fetchStarted, setFetchStarted] = useState(false);
    const [items, setItems] = useState([]); // ALL items, as the backend returns them
    const [limitedItems, setLimitedItems] = useState([]); // limited by the search from the 'startpage' (which can be filtered further)
    const [countries, setCountries] = useState([]);
    const [occStatus, setOccStatuses] = useState([]);

    // optimisation
    const [searchWillLimit, setSearchWillLimit] = useState(false);

    const fetchAll = useCallback(async () => {
        try {
            if (fetchStarted) return;
            setFetchStarted(true);
            console.log(`Fetching target list items...`);
            const response = await fetch('api/Targetlist');
            const data = await response.json();
            let newItems = data.items;
            if (DEVELOPMENT_LIMIT_TO && DEVELOPMENT_LIMIT_TO !== Number.MAX_SAFE_INTEGER) {
                newItems = newItems.slice(0, DEVELOPMENT_LIMIT_TO);
            }
            console.log(`got ${data.items.length} items, limited to ${newItems.length} items`);
            setItems(newItems.map(item => createTargetlistItemFromJson(item)));
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, [setItems, fetchStarted]);

    // fetch all countries currently 'in use', sorted by how many times they appear
    const getCountries = useCallback(() => {
        const countries = items.reduce((countriesMap, item) => {
            item[COUNTRY_KEY].forEach(country => {
                if (!countriesMap[country]) {
                    countriesMap[country] = { name: country, id: uuidv4(), count: 1 };
                } else {
                    countriesMap[country].count += 1;
                }
            });
            return countriesMap;
        }, {});
        return Object.values(countries).sort((a, b) => b.count - a.count);
    }, [items]);

    const getOccurrenceStatuses = useCallback(() => {
        const statusLabels = {
            '0a': 'Reported correctly, to be refined',
            '1': 'Indigenous (undetermined)',
            '1a': 'Indigenous: native species',
            '1b': 'Indigenous: incidental/periodical species',
            '2': 'Introduced (undetermined)',
            '2a': 'Introduced: at least 100 years independent survival',
            '2b': 'Introduced: 10-100 years independent survival',
            '2c': 'Introduced: less than 10 years independent survival',
            '2d': 'Introduced: incidental import',
        };
        const statuses = items.reduce((statusesMap, item) => {
            const statusList = item[OCCURRENCE_STATUS_KEY]?.split(',').map(status => status.trim()) || [];
            statusList.forEach(status => {
                if (!statusesMap[status]) {
                    statusesMap[status] = { name: status, description: statusLabels[status], id: uuidv4(), count: 1 };
                } else {
                    statusesMap[status].count += 1;
                }
            });
            return statusesMap;
        }, {});
        return Object.values(statuses);
    }, [items]);

    useEffect(() => {
        const newCountries = getCountries();
        const newStatuses = getOccurrenceStatuses();
        setCountries(newCountries.filter(country => country.name !== ''));
        setOccStatuses(newStatuses.filter(status => status.name !== ''));
    }, [getCountries, getOccurrenceStatuses])

    // get all the items, with filters applied
    // (we filter countries and percentages in advance, too slow to leave this up to tanstack, and global filter didn't work properly)
    const getAll = useCallback(async ({
        groupBy = "",
        localityFilter = [],
        percentageFilter = {},
        occurrenceStatusFilter = [],
        wantedFilter,
        searchFilter
    }) => {
        try {
            // don't bother if we'll have to redo this in more limited list in a second
            if (searchWillLimit) return [];
            if (!items.length) {
                await fetchAll();
            }
            // if no search is done, simply use all the items
            let filteredItems = limitedItems.length ? limitedItems : items;

            console.log(`got ${filteredItems.length} items, filtering them`);
            filteredItems = filterByOccurrenceStatus(filteredItems, occurrenceStatusFilter);
            console.time(`filterByString ${searchFilter} `)
            filteredItems = filterByString(filteredItems, searchFilter, specificityList);
            console.timeEnd(`filterByString ${searchFilter} `)
            if (wantedFilter) {
                filteredItems = filterByStatus(filteredItems, wantedFilter);
            }
            if (groupBy.length !== 0) {
                filteredItems = filterByLocality(filteredItems, localityFilter);
                // special case, no grouping is done when doing species (that's simply the whole list)
                if (groupBy !== 'species') {
                    //	go from [ {item1, genus: 'A'}, {item2, genus: 'A'}, {item3, genus: 'B'}, ... ]
                    //	to [ {'A': [ {item1}, {item2} ]}, {'B', [ {item3} ]}, ... ]
                    //
                    console.time(`groupBy ${groupBy} `)
                    const groups = filteredItems.reduce((grouped, item) => {
                        const key = item[groupBy];
                        if (!grouped[key]) {
                            grouped[key] = [item];
                        } else {
                            grouped[key].push(item);
                        }
                        return grouped;
                    }, {});
                    console.timeEnd(`groupBy ${groupBy} `)

                    console.time(`groupBy consolidated ${groupBy} `)
                    // e.g. groups is something like { 'A': [item1, item2], 'B': [item3], 'C': [item4, item5, item6] }
                    // when grouped by genus (genus being 'A' or 'B' or 'C')
                    // 
                    //	now collapse the list, so that we end up with [A: newItem, B: newItem, ... ]
                    let grouped = [];
                    Object.keys(groups).forEach((key) => {
                        if (groups[key].length > 1) {
                            const allKeys = Object.keys(groups[key][0]); // every item has the same keys, so simply take all those of the first one...
                            let combined = {};
                            allKeys.forEach((sumKey) => { // ... and then for each key combine all the values into one
                                combined[sumKey] = groupTargetlistItemsByKey(sumKey, groups[key], { groupBy });
                            });
                            grouped.push(combined)
                        }
                        // else, it is a single item, so we don't need to do anything

                    });
                    console.timeEnd(`groupBy consolidated ${groupBy} `)
                    filteredItems = grouped;
                }
            }
            // don't filter before grouping or everthing will become 100% (since only those with percentage remain)
            Object.keys(percentageFilter).forEach(filter => filteredItems = filterByPercentage(filteredItems, { percentageKey: filter, ...percentageFilter[filter] }));
            return filteredItems;
        } catch (error) {
            console.error(`ERROR in getAll: ${error}`)
            throw error;
        }
    },
        [fetchAll, limitedItems, items, searchWillLimit])

    // used by the start search bar, will return a map with as keys "Class" "family" and values an array of matches
    // to be shown in the dropdown of the search bar
    const searchItems = useCallback(async (searchString = '', limitTo = 5) => {
        try {
            if (!searchString || searchString.length < 1) {
                return {};
            }
            setSearchWillLimit(true);
            if (!items.length) {
                await fetchAll();
            }
            console.time(`searchItems ${searchString} `)
            // filter the original data with this string	
            let filteredItems = filterByString(items, searchString, specificityList);

            // start with an empty set for each specificity
            let result = specificityList.reduce((result, specificity) => {
                result[specificity] = new Set();
                return result;
            }, {});

            // now for every row add to each set's specificity the respective value
            let groupedBySpecificity = filteredItems.reduce((result, row) => {
                specificityList.forEach(specificity => {
                    if (stringCompareFn(row[specificity], searchString)) {
                        result[specificity].add(row[specificity]);
                    }
                });
                return result;
            }, result);

            // that's it, now we have to return the first X results for each specificity
            let limitedResult = Object.entries(groupedBySpecificity).reduce((result, [specificity, set]) => {
                result[specificity] = { count: set.size, items: [...set].slice(0, limitTo) };
                return result;
            }, {});
            console.timeEnd(`searchItems ${searchString} `)
            return limitedResult;
        } catch (error) {
            console.error(`ERROR in searchItems: ${error}`)
            throw error;
        } finally {
            setSearchWillLimit(false);
        }
    }, [fetchAll, items])

    // after the dropdown is shown, the user selects one of the items within a taxonomy
    // this function will limit the table data to that choice, and it will return the parent 'taxonomies' 
    // to be used in the breadcrumbs
    const filterBySearchTerm = useCallback(async ({ searchTerm, forTaxonomy = Specificity.unknown }) => {
        try {
            if (!searchTerm || searchTerm.length < 1) {
                return {};
            }
            if (forTaxonomy === Specificity.unknown) {
                return {};
            }
            setSearchWillLimit(true);
            if (!items.length) {
                await fetchAll();
            }
            // filter the original data with this string	
            let filteredItems = filterByString(items, searchTerm, [forTaxonomy], true);
            setLimitedItems([...filteredItems]);
            if (filteredItems.length === 0) {
                console.error(`ERROR: No items found when looking for ${searchTerm} in ${forTaxonomy}`);
                return {};
            }
            return specificityList.filter(specificity => isEqualOrMoreSpecific(forTaxonomy, specificity)).reduce((result, specificity) => { result[specificity] = filteredItems[0][specificity]; return result; }, {})

        } finally {
            setSearchWillLimit(false);
        }
    }, [fetchAll, items]);

    // will return a set of three percentages for a certain search term within a certain taxonomy
    const chartPercentages = useCallback(async ({
        groupBy = Specificity.unknown,
        searchTerm = "",
    }) => {
        if (!searchTerm || searchTerm.length < 1 || groupBy === Specificity.unknown) {
            return {};
        }
        setSearchWillLimit(true);
        if (!items.length) {
            await fetchAll();
        }
        let filteredItems = filterByString(items, searchTerm, specificityList);
        const groups = filteredItems.reduce((grouped, item) => {
            const key = item[groupBy];
            if (!grouped[key]) {
                grouped[key] = [item];
            } else {
                grouped[key].push(item);
            }
            return grouped;
        }, {});
        let combined = {};
        ['allPercentageCoverage', 'arisePercentageCoverage', 'otherPercentageCoverage'].forEach(key => combined[key] = groupTargetlistItemsByKey(key, groups[searchTerm], { groupBy }));
        setSearchWillLimit(false);
        return combined;

    }, [fetchAll, items]);

    return {
        getAll,
        searchItems,
        filterBySearchTerm,
        chartPercentages,
        countries,
        occStatus
    };
}