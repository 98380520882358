import { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import TargetlistGrid from './target_list_grid';
import TargetlistSearch from './target_list_search';
import TargetlistFilterSidebar from './target_list_filter_sidebar';
import TargetlistGridHeader from './target_list_grid_header';
import { isEqualOrMoreSpecific, specificityList } from '../../model/specificity';
import { TableStateContext } from '../../model/table_state_context';
import ChartsCarousel from './charts-carousel';
import { GoogleChartsProvider } from '../../useGoogleCharts';

// contains alls the other components that make up the target list page
// also deals with url parameters etc.
export default function TargetlistContainer() {
    const [searchParams] = useSearchParams();
    const [urlSearchString, setUrlSearchString] = useState(undefined);
    const [urlShowFrom, setUrlShowFrom] = useState(undefined);
    const [urlSelectedIndex, setUrlSelectedIndex] = useState(0);
    const [hideColumns, setHideColumns] = useState([]);
    const { filterBySearchTerm } = useContext(TableStateContext);
    const [carouselItems, setCarouselItems] = useState([]);
    const [tableVisible, setTableVisible] = useState(false);
    const [groupBy, setGroupBy] = useState('species');// Initialize to default value 'species'

    const tableRef = useRef(null);
    if (tableVisible) {
        tableRef.current?.classList?.add("show");
    } else {
        tableRef.current?.classList?.remove("show");
    }

    useEffect(() => {
        if (searchParams.has('q')) {
            setUrlSearchString(searchParams.get('q'))
        } else {
            setUrlSearchString(undefined)
        }
        if (searchParams.has('showfrom')) {
            setUrlShowFrom(searchParams.get('showfrom'))
        } else {
            setUrlShowFrom(undefined)
        }
        if (searchParams.has('selected')) {
            setUrlSelectedIndex(Number(searchParams.get('selected')))
        } else {
            setUrlSelectedIndex(0)
        }

    }, [searchParams])

    useEffect(() => {
        if (!urlSearchString || !urlShowFrom) {
            // one already set but not the other, do nothing yet
        }
        else {
            filterBySearchTerm({ searchTerm: urlSearchString, forTaxonomy: urlShowFrom }).then(result => {
                // convert object like {kingdom: "Animalia", phylum: "Chordata", ...} to array [["kingdom", "Animalia"], ["phylum", "Chordata"], ...]
                // we start with all taxonomies, so the order is correct, then filter out the ones that are not in the result
                // and then convert to array
                const resultAsArray = specificityList.filter(spec => Object.keys(result).includes(spec)).map(spec => ([spec, result[spec]]));
                setCarouselItems(resultAsArray);
            });
        }
    }, [urlSearchString, urlShowFrom, filterBySearchTerm])

    useEffect(() => {
        if (carouselItems) {
            const currentCarouselItem = carouselItems[carouselItems.length - 1 + urlSelectedIndex];
            if (currentCarouselItem) {
                filterBySearchTerm({ searchTerm: currentCarouselItem[1], forTaxonomy: currentCarouselItem[0] })
                const hiddenSpecificities = specificityList.filter(spec => !isEqualOrMoreSpecific(spec, currentCarouselItem[0]));
                const percentageColumns = currentCarouselItem[0] === 'species'
                    ? ['allPercentageCoverage', 'arisePercentageCoverage', 'otherPercentageCoverage', 'speciesTotal']
                    : ['allPercentageCoverage', 'arisePercentageCoverage', 'otherPercentageCoverage'];

                setHideColumns([...hiddenSpecificities, ...percentageColumns]);

                if (groupBy !== 'species') {
                    setHideColumns(prevHideColumns => [...prevHideColumns, 'speciesStatus', 'collected']);
                } else if (groupBy === 'species') {
                    setHideColumns(prevHideColumns => [...prevHideColumns, 'speciesTotal']);
                }
            }
        }
    }, [urlSelectedIndex, filterBySearchTerm, carouselItems, setHideColumns, groupBy])

    const toggleFilters = function () {
        var filters = document.getElementById("filters-sidebar");
        filters.focus();
        filters?.classList.toggle("hide");
        filters?.classList.toggle("show");
    }

    // const groupByList = ['kingdom', 'phylum', 'class', 'order', 'family', 'genus', 'species'];
    return (
        <div className="tl-results-container">
            {!urlSearchString && !urlShowFrom ?
                <div className="container-main table-page">
                    <TargetlistSearch />
                </div>
                :
                <>
                    <TargetlistFilterSidebar toggleFilters={toggleFilters} />
                    <GoogleChartsProvider>
                        <ChartsCarousel showTable={() => setTableVisible(true)} carouselItems={carouselItems} />
                    </GoogleChartsProvider>
                    <div className="container-main table-page results-table" id="table-container" ref={tableRef} >
                        <TargetlistGridHeader toggleFilters={toggleFilters} hideTable={() => setTableVisible(false)} onGroupByChange={setGroupBy} />
                        <TargetlistGrid hideColumns={hideColumns} />
                    </div>
                </>
            }
        </div>
    );
}