import { useEffect, useState, useCallback } from 'react';
import { TableStateProvider } from '../model/table_state_context';
import TargetlistContainer from '../components/target_list/target_list_container';
import InfoPopup from "../components/info_popup";

export default function Targetlist() {
    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        const navbar = document.getElementById("navbar-main");
        const hamburgermenu = document.getElementById("hamburger-menu");
        const logo = document.getElementById("navbar-logo");
        navbar.classList.remove("transparent");
        navbar.classList.add("transparent-dark-text");
        hamburgermenu.classList.remove("transparent");
        hamburgermenu.classList.add("transparent-dark-text");
        logo.src = "/images/logo-arise-colors.svg";
    }, []);

    window.addEventListener("scroll", function () {
        var changeOffset = (window.outerHeight - 150); //where the menu needs to change
        if (window.scrollY > changeOffset) {
            document.getElementById("navbar-main").classList.remove("transparent");
            document.getElementById("hamburger-menu").classList.remove("transparent");
        }
        if (window.scrollY < changeOffset) {
            document.getElementById("navbar-main").classList.add("transparent");
            document.getElementById("hamburger-menu").classList.add("transparent");
        }
    })

    const onPopupBlur = useCallback((e) => {
        var info = document.getElementById("info-popup");
        setPopupVisible(false);
        if (e.relatedTarget == null) {
            info.classList.remove("show");
        } else if (e.relatedTarget.id !== "info-popup" || e.relatedTarget.id == null){
            info.classList.remove("show");
        }
    }, [popupVisible]);

    return (
        <TableStateProvider>
            <InfoPopup toggleInfoPopup={onPopupBlur} />
            <TargetlistContainer />
        </TableStateProvider>

    );
}